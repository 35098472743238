<template>
    <NavBar />
    <router-view/>
</template>

<style src="@/css/main.scss" lang="scss" />


<script>
/// import FooterView from '@/components/FooterView.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  components: {
    /// FooterView,
    NavBar,
  },
}
</script>
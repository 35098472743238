<template>
  <div id="map" style="width: 100%; height: 100%;"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import { db } from '@/firebaseConfig'; 
import { collection, getDocs } from 'firebase/firestore'; 
import axios from 'axios'; 

export default {
  props: ['restaurants','selectedRestauarant'],
  data() {
    return {
      map: null,
      markers: [],
      mapboxToken: 'pk.eyJ1IjoidHJldm9yY2Fwb3p6YSIsImEiOiJjbTBycmNpZTcwYnVlMnFvazltOXIwNXI4In0.t5lsN6YxPJgKYKQUzYak1Q',
    };
  },

  mounted() {

    mapboxgl.accessToken = this.mapboxToken;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/trevorcapozza/cm0sqivq0002001q0a4hk0qxj',
      center: [-80, 30],
      zoom: 0.5,
      minZoom: 0.5,
      projection: 'globe',
    });

    this.map.on('zoom', this.toggleMarkersVisibility);
    this.map.on('resize', () => {
    this.updateMarkersPosition();  
  });

    this.fetchRestaurantsData();
  },

  watch: {
  selectedRestaurant(newRestaurant) {
    this.highlightSelectedMarker(newRestaurant);
    this.updateMarkersPosition(); 
  },
},

  methods: {

    updateMarkersPosition() {
      this.markers.forEach(marker => {
        const { lng, lat } = marker.getLngLat();
        marker.setLngLat([lng, lat]); 
      });
    },

    applyBounds() {
      if (this.cityBounds) {
        this.map.fitBounds(this.cityBounds, {
          padding: 50,
          maxZoom: 12,
        });
      }
    },

    fetchRestaurantsData() {
      const restaurantsCollection = collection(db, 'restaurants'); 

      getDocs(restaurantsCollection).then(snapshot => {
        snapshot.forEach(doc => {
          const restaurant = doc.data();
          const address = restaurant.address;
          this.geocodeAddress(address, restaurant);
        });
      }).catch(error => {
        console.error("Error fetching restaurants: ", error);
      });
    },

    createCustomMarkerElement(restaurant) {
      const el = document.createElement('div');
      el.className = 'custom-marker';
      el.style.cursor = 'pointer';
      el.title = restaurant.name; 

      const img = document.createElement('img');
      img.src = require('@/assets/map-marker.svg');
      img.className = 'marker-svg';
      img.style.width = '30px';  // You can adjust the size as needed
      img.style.height = '30px'; // You can adjust the size as needed

      el.appendChild(img); // Add the image to the marker element

      return el;
    },

    geocodeAddress(address, restaurant) {
      const encodedAddress = encodeURIComponent(address);
      const geocodeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?access_token=${this.mapboxToken}`;

      axios.get(geocodeUrl).then(response => {
        const data = response.data;
        if (data && data.features && data.features.length > 0) {
          const [lng, lat] = data.features[0].geometry.coordinates;
          console.log(`Geocoded coordinates for ${restaurant.name}:`, lng, lat);
        
          restaurant.location = { lng, lat };
        
          const marker = new mapboxgl.Marker({
            element: this.createCustomMarkerElement(restaurant),
          })
            .setLngLat([lng, lat])
            .addTo(this.map);
        
          this.markers.push(marker);
        
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            offset: 25,
          });
        
          marker.getElement().addEventListener('mouseenter', () => {
            const description = restaurant.description || 'No description available';
          
            const shortDescription = description.length > 35 
              ? description.substring(0, 50) + "..." 
              : description;
          
            popup.setLngLat([lng, lat])
              .setHTML(`
                <div class="hover-popup">
                  <div class="hover-left">
                    <h3>${restaurant.name}</h3>
                    <p>${shortDescription}</p>
                  </div>
                  <img src="${restaurant.image}" alt="${restaurant.name}" class="hover-image"/>
                </div>
              `)
              .addTo(this.map);
          });
        
          marker.getElement().addEventListener('mouseleave', () => {
            popup.remove();
          });
        

          marker.getElement().addEventListener('click', () => {
          
            this.$emit('restaurant-selected', restaurant);
          
            const { lng, lat } = restaurant.location;
            if (typeof lng === 'number' && typeof lat === 'number') {
              this.map.flyTo({
                center: [lng, lat],
                zoom: 14,
                essential: true 
              });
            }
          });
        
          this.toggleMarkersVisibility();
        
        } else {
          console.error(`No results for address: ${address}`);
        }
      }).catch(error => {
        console.error(`Error geocoding address: ${address}`, error);
      });
    },

    toggleMarkersVisibility() {
      const zoomLevel = this.map.getZoom();
      const shouldHideMarkers = zoomLevel < 6; 
    
      this.markers.forEach(marker => {
        if (shouldHideMarkers) {
          marker.getElement().style.display = 'none'; 
        } else {
          marker.getElement().style.display = 'block'; 
        }
      });
    },

    zoomOutToCities(cities) {
      const bounds = new mapboxgl.LngLatBounds();
      cities.forEach(city => {
        if (city.lng && city.lat) {
          bounds.extend([city.lng, city.lat]); 
        }
      });
      this.map.fitBounds(bounds, { padding: 50, maxZoom: 4 });
    },

    zoomInToCity(city) {
      this.map.flyTo({
        center: [city.lng, city.lat],
        zoom: 12, 
        essential: true, 
      });
    },

    centerMapOnLocation([lng, lat]) {
      this.map.flyTo({
        center: [lng, lat],
        zoom: 14,
        essential: true,
      });
    },

    zoomOutToZero() {
      this.map.flyTo({
        center: [-80, 30],  
        zoom: 0.5,
        essential: true, 
      });
    },
  },
};
</script>
<template>
  <div class="container">
    
    <!-- Left Section: Restaurant List -->
    <div class="restaurant-list" ref="restaurantList">
      
      <!-- City List -->
      <div v-if="!selectedCity && !selectedRestaurant" class="welcome">
        <p>welcome to <span class="nora-text">nora.</span><br>select a city below for our<br>recommended hit list.</p>
        <ul class="city-ul">
          <li v-for="city in cities" :key="city.name" class="city-item" @click="selectCity(city)">
            <h3>{{ city.name }}</h3>
          </li>
        </ul>
      </div>

      <!-- Restaurant List (appears when a city is selected) -->
      <div v-if="selectedCity && !selectedRestaurant">
        <div class="list-title">
          <button @click="backToCityList" class="city-back">
            <span class="city-arrow">←</span>
          </button>
          <h3>{{ selectedCity.name }}</h3>
        </div>

        <ul class="restaurant-ul">
          <li
            v-for="restaurant in restaurants"
            :key="restaurant.name"
            class="list-organizer"
            @click="showRestaurantDetails(restaurant)"
          >
            <div class="restaurant-info">
              <h3>{{ restaurant.name }}</h3>
              <p>{{ restaurant.descriptionPreview }}</p>
              <div class="links">
                <p v-if="restaurant.website">
                  <a :href="restaurant.website" target="_blank" @click.stop>Website</a>
                </p>
                <p v-if="restaurant.reservation">
                  <a :href="restaurant.reservation" target="_blank" @click.stop>Reservation</a>
                </p>
              </div>
            </div>
            <div class="image-box">
              <img :src="restaurant.image" alt="Restaurant Image" class="restaurant-image" />
            </div>
          </li>
        </ul>
      </div>

      <!-- Restaurant Details -->
      
      <div v-if="selectedRestaurant" class="restaurant-detail">
        <button @click="backToRestaurantList" class="back-button">
          <span class="back-arrow">←</span>
        </button>
        <img :src="selectedRestaurant.image" alt="Restaurant Image" class="restaurant-detail-image" />
        <div class="full-details">
          <h2>{{ selectedRestaurant.name }}</h2>
          <p>{{ selectedRestaurant.description }}</p>
          <div class="links">
            <p v-if="selectedRestaurant.website">
              <a :href="selectedRestaurant.website" target="_blank">Website</a>
            </p>
            <p v-if="selectedRestaurant.reservation">
              <a :href="selectedRestaurant.reservation" target="_blank">Reservation</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Section: Map -->
    <MapView 
      ref="mapViewComponent"
      class="map" 
      :selected-restaurant="selectedRestaurant"
      @restaurant-selected="showRestaurantDetails"/>

    <!-- City Dropdown for Mobile -->
    <div class="city-dropdown" v-if="!selectedCity && !selectedRestaurant">
      <select @change="selectCity($event.target.value)">
        <option value="" disabled selected>Select a City</option>
        <option v-for="city in cities" :key="city.name" :value="city.name">
          {{ city.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import MapView from '@/components/MapView.vue';
import mapboxgl from 'mapbox-gl';
import { db } from '@/firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';
import axios from 'axios';

export default {
  components: {
    MapView,
  },

  data() {
    return {
      cities: [],
      restaurants: [],
      selectedCity: null,
      selectedRestaurant: null,
      cityBounds: null,
      mapboxToken: 'pk.eyJ1IjoidHJldm9yY2Fwb3p6YSIsImEiOiJjbTBycmNpZTcwYnVlMnFvazltOXIwNXI4In0.t5lsN6YxPJgKYKQUzYak1Q',
      scrollPosition: 0,
    };
  },

  mounted() {
    this.fetchCities();
    window.addEventListener('resize', this.updateMapPadding);
  },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateMapPadding);
  },

  methods: {

    updateMapPadding() {
      const isMobile = window.innerWidth <= 820;
      const map = this.$refs.mapViewComponent.map;
      
      let bottomPadding = 0;

      if (isMobile && this.selectedRestaurant) {
        bottomPadding = window.innerHeight * 0.80; 
      } else if (isMobile && this.selectedCity && !this.selectedRestaurant) {
        bottomPadding = window.innerHeight * 0.38; 
      }

      console.log('Applying map padding:', bottomPadding); 

      map.setPadding({
        top: 0,
        bottom: bottomPadding,
        left: 0,
        right: 0
      });

      map.resize();

      console.log('Current map padding:', map.getPadding());
    },

    generateDescriptionPreview(description) {
      if (!description) {
        return ""; 
      }
      return description.length > 30 ? description.substring(0, 80) + "..." : description;
    },
    
    backToCityList() {
      this.selectedCity = null; 
      if (this.$refs.mapViewComponent) {
        this.$refs.mapViewComponent.zoomOutToZero(); 
      }
    },
    
    selectCity(cityNameOrObject) {
      let selectedCity = cityNameOrObject;
      if (typeof cityNameOrObject === 'string') {
        selectedCity = this.cities.find(city => city.name === cityNameOrObject);
      }
    
      if (selectedCity) {
        this.selectedCity = selectedCity;
        this.restaurants = [];
        this.fetchRestaurants(selectedCity.name);
      
        this.$nextTick(() => {
          const restaurantList = this.$refs.restaurantList;
          restaurantList.classList.add('show-on-mobile');
        
          this.$nextTick(() => {
            this.updateMapPadding();
          
            const map = this.$refs.mapViewComponent.map;
            map.resize(); 
          
            if (this.cityBounds) {
              map.fitBounds(this.cityBounds, {
                padding: 50,
                maxZoom: 12,
              });
            }
          });
        });
      }
    },

    fetchCities() {
      const restaurantsCollection = collection(db, 'restaurants');

      getDocs(restaurantsCollection)
        .then(snapshot => {
          const citySet = new Set();
          const citiesWithCoordinates = [];

          snapshot.forEach(doc => {
            const restaurant = doc.data();
            if (restaurant.city && !citySet.has(restaurant.city)) {
              citySet.add(restaurant.city);
              citiesWithCoordinates.push({ name: restaurant.city });
            }
          });

          citiesWithCoordinates.sort((a, b) => a.name.localeCompare(b.name));

          this.cities = citiesWithCoordinates;
        })
        .catch(error => {
          console.error('Error fetching cities:', error);
        });
    },

    getCoordinatesFromAddress(address) {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${this.mapboxToken}`;

      return axios.get(url).then(response => {
        const data = response.data;
        if (data && data.features && data.features.length > 0) {
          const [lng, lat] = data.features[0].geometry.coordinates;
          return { lng, lat }; 
        } else {
          console.error(`No results for address: ${address}`);
          return null; 
        }
      }).catch(error => {
        console.error(`Error geocoding address: ${address}`, error);
        return null; 
      });
    },

  
  fetchRestaurants(cityName) {
    const restaurantsCollection = collection(db, 'restaurants');
    const cityQuery = query(restaurantsCollection, where('city', '==', cityName));

    getDocs(cityQuery)
      .then(snapshot => {
        const restaurantData = [];
        const geocodePromises = [];
        const bounds = new mapboxgl.LngLatBounds(); 

        snapshot.forEach(doc => {
          const restaurant = doc.data();
          
          restaurant.descriptionPreview = this.generateDescriptionPreview(restaurant.description);

          const geocodePromise = this.getCoordinatesFromAddress(restaurant.address)
            .then(coordinates => {
              if (coordinates) {
                restaurant.location = coordinates;
                bounds.extend([coordinates.lng, coordinates.lat]);
                restaurantData.push(restaurant);
              }
            });
          geocodePromises.push(geocodePromise);
        });

        Promise.all(geocodePromises).then(() => {
          restaurantData.sort((a, b) => a.name.localeCompare(b.name));
          this.restaurants = restaurantData; 
          this.cityBounds = bounds;

          if (!bounds.isEmpty() && this.$refs.mapViewComponent) {
            this.$refs.mapViewComponent.map.fitBounds(bounds, {
              padding: 50,
              maxZoom: 12,
            });
          } else {
            console.error("No valid restaurant locations to zoom into.");
          }
        });
      })
      .catch(error => {
        console.error('Error fetching restaurants:', error);
      });
    },

    showRestaurantDetails(restaurant) {
      this.selectedRestaurant = restaurant;

      setTimeout(() => {
        this.updateMapPadding();
        const map = this.$refs.mapViewComponent.map;

        map.resize();
      
        this.centerMap(restaurant);
      });
    },

    backToRestaurantList() {
      this.selectedRestaurant = null; 

      this.$nextTick(() => {
        const restaurantList = document.querySelector('.restaurant-list');
        if (restaurantList) {
          restaurantList.scrollTop = this.scrollPosition;
        }

        if (this.cityBounds && this.$refs.mapViewComponent) {
          const map = this.$refs.mapViewComponent.map;

          this.updateMapPadding();

          map.fitBounds(this.cityBounds, {
            padding: 50,
            maxZoom: 12,
          });
        }
      });
    },

    centerMap(restaurant) {
      const { lng, lat } = restaurant.location || {};
      if (this.$refs.mapViewComponent && typeof lng === 'number' && typeof lat === 'number') {
        this.$refs.mapViewComponent.centerMapOnLocation([lng, lat]); 
      }
    },
  },
};
</script>

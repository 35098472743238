<template>
    <div class="nav-home">
        <router-link to="/"><img class="logo" src="../assets/nora-logo-light.svg"></router-link>
        <!-- <router-link to="/about"><h2>Who is Nora?</h2></router-link> -->
    </div>
</template>

<script>
export default {
    name: 'NavBar',
}
</script>
// Import only the functions you need from Firebase
import { initializeApp } from 'firebase/app';  // Import correctly to avoid "no-undef" error
import { getFirestore } from 'firebase/firestore';  // Firestore import
import { getAnalytics } from 'firebase/analytics';

// Firebase configuration from your Firebase console
const firebaseConfig = {
    apiKey: "AIzaSyDkmEtBY8S74xxmlBJVGs7xr9SHCm_ZYCY",
    authDomain: "nora-73a7d.firebaseapp.com",
    projectId: "nora-73a7d",
    storageBucket: "nora-73a7d.appspot.com",
    messagingSenderId: "967612555",
    appId: "1:967612555:web:f599eadffff01651ce1c8e",
    measurementId: "G-T82HGZBS6J"
  };

// Initialize Firebase (no need to store the app variable if unused)
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app)

// Initialize Firestore
const db = getFirestore();

export { db,app,analytics };